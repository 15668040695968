import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

const Modal = ({ open, handleClose, handleOpen }) => {
    const [reason, setReason] = useState('');

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    return (
        <Dialog open={open} onClose={handleClose} sx={{ background: 'rgba(0,0,0,0.4)' }} PaperProps={{
            style: {
                width: '450px',
            },
        }}>
            <DialogTitle>Prośba o zwrot</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="reason-select-label">Powód zwrotu</InputLabel>
                    <Select
                        labelId="reason-select-label"
                        id="reason-select"
                        value={reason}
                        label="Powód zwrotu"
                        onChange={handleReasonChange}
                    >
                        <MenuItem value={'Defekt produktu'}>Defekt produktu</MenuItem>
                        <MenuItem value={'Niezgodność z opisem'}>Niezgodność z opisem</MenuItem>
                        <MenuItem value={'Zmiana zdania'}>Zmiana zdania</MenuItem>
                        <MenuItem value={'Inny powód'}>Inny powód</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Anuluj</Button>
                <Button
                    onClick={() => {
                        // Obsługa wysłania prośby o zwrot
                        console.log('Wybrany powód:', reason);
                        handleClose();
                    }}
                    disabled={!reason}
                >
                    Wyślij
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Modal;
