import { Box, CircularProgress, Container, IconButton, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import TableRowsTransacts from "./TableRowsTransacts";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import uuid from "react-uuid";

const Transactions = () => {

    const [rowsPerPage, setRowsPerPage] = useState(0)
    const [offsetPage, setOffsetPage] = useState(0)
    const [sortBy, setSortBy] = useState("")
    const [isRowsPerPage, setIsRowsPerPage] = useState(false)
    const [transacts, setTransacts] = useState([])
    const [transactsCount, setTransactsCount] = useState(0)

    const getProjectsSort = (data) => {
        setSortBy(data)
        axios("/investments/my-transactions/", {
            params: {
                "ordering": data,
                "limit": rowsPerPage,
                "offset": offsetPage,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTransacts(res.data.results)
            })
            .catch(err => console.error(err))
    }

    const handleFilterRows = (filterBy) => {
        setRowsPerPage(filterBy)
        axios("/investments/my-transactions/", {
            params: {
                "ordering": sortBy,
                "limit": filterBy,
                "offset": offsetPage,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTransacts(res.data.results)
                setIsRowsPerPage(true)
            })
            .catch(err => console.error(err))
    };
    const handlePage = (e, value) => {

        const offset = value * rowsPerPage - rowsPerPage
        setOffsetPage(offset)

        axios("/investments/my-transactions/", {
            params: {
                "ordering": sortBy,
                "limit": rowsPerPage,
                "offset": offset,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTransacts(res.data.results)
                setIsRowsPerPage(true)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        axios("/investments/my-transactions/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTransactsCount(res.data.count)
                setTransacts(res.data.results)
            })
            .catch(err => console.error(err))
    }, [])

    return (
        <Container maxWidth={'lg'} sx={{ marginTop: "100px", marginBottom: "20px" }}>
            <Paper sx={{ padding: "20px", overflow: "hidden", maxWidth: "100%" }} marginLeft={2}>
                <Box marginBottom={2}>
                    <Typography fontSize={18} marginBottom={2}>Moje transakcje</Typography>
                    <Typography fontSize={14}>
                        <span>Wyświetla </span>
                        <Select
                            value={rowsPerPage}
                            onChange={(event) => handleFilterRows(event.target.value)}
                            size='small'
                        >
                            <MenuItem value={5}>5 </MenuItem>
                            <MenuItem value={10}>10 </MenuItem>
                            <MenuItem value={20}>20 </MenuItem>
                        </Select>
                        <span> wpisów</span>
                    </Typography>
                </Box>
                <Box sx={{ backgroundColor: "#fff", padding: "16px", maxWidth: "100%", boxShadow: "0px 2px 17px rgba(0, 0, 0, 0.1) ", overflow: "auto", }} marginBottom={2}>
                    <Box sx={{ minWidth: "800px", }} >
                        <div style={{ position: "sticky", top: 0, }}>
                            <Table style={{ tableLayout: "fixed", }}  >
                                <TableHead>
                                    <TableRow
                                        style={{
                                            backgroundColor: "#ffffff",
                                            height: "35px",
                                            borderBottom: "1px solid #f5f5f5"
                                        }}
                                    >
                                        <TableCell>
                                            Nazwa inwestycji
                                            <IconButton onClick={() => getProjectsSort("project__name")}>
                                                <ImportExportIcon sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            Status inwestycji
                                            <IconButton onClick={() => getProjectsSort("project__status")}>
                                                <ImportExportIcon sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            Status transakcji
                                            <IconButton onClick={() => getProjectsSort("status")}>
                                                <ImportExportIcon sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            Kwota
                                            <IconButton onClick={() => getProjectsSort("amount")}>
                                                <ImportExportIcon sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>Akcje</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                        <div style={{ height: "300px", backgroundColor: "#ffffff", overflow: "auto", }}>
                            <Table style={{ tableLayout: "fixed", }}>
                                <TableBody>
                                    {
                                        transacts.map(row => {
                                            return (
                                                <TableRowsTransacts key={uuid()} title={row.project.name} status={row.project.status} investedAmount={row.amount} statusTransact={row.status} />
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography>{transacts.length - (transacts.length - 1) + offsetPage} - {transacts.length > 0 ? offsetPage + transacts.length : <CircularProgress size={15} sx={{ marginBottom: "-2px" }} />}</Typography>
                    <Pagination onChange={handlePage} sx={{ textAlign: "center" }} count={isRowsPerPage ? transactsCount / rowsPerPage + 1 : 1} defaultPage={offsetPage === 0 ? 1 : offsetPage / rowsPerPage + 1} siblingCount={0} boundaryCount={2} />
                </Box>
            </Paper>
        </Container>
    )
}

export default Transactions;