import { Box, IconButton, ListItemIcon, MenuItem, Modal, TableCell, TableRow, Tooltip } from '@mui/material';
import { GoEye, GoGear } from "react-icons/go";
import { TfiFile, TfiFiles, TfiMedall, TfiMinus } from "react-icons/tfi";
import { useState } from 'react';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';

const formatPrice = (priceIn) => {
    let PLN = Math.floor(priceIn / 100);
    let grosze = priceIn % 100;

    let PLNZSpacjami = PLN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return PLNZSpacjami + (grosze !== 0 ? '.' + (grosze < 10 ? '0' : '') + grosze : '.00');
}

const TableRows = ({
    title = "",
    status = "",
    investedAmount = 0,
    foundsWithdrawn = 0,
    totalYield = 0,
    foundsToBeWithdrawn = 0,
    endDate = null,
    id = null,
    handleOpenModal
}) => {

    const navigate = useNavigate();
    // Obsłuż brak endDate
    const date = endDate ? new Date(endDate) : new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Dodaj 1, bo miesiące w JS są liczone od 0
    const year = date.getFullYear();

    const [open, setOpen] = useState(null);

    const handleClose = () => {
        setOpen(!open);
    };

    const handleTranslate = (status) => {
        switch (status) {
            case "confirmed":
                return "Zakończona sukcesem";
            case "canceled":
                return "Zakończona niepowodzeniem";
            case "signed_by_investor":
                return "Podpisana przez inwestora";
            case "closed":
                return "Zamknięta";
            case "vindication":
                return "W windykacji";
            case "pending_verification":
                return "W trakcie weryfikacji";
            case "open":
                return "Otwarty"
            default:
                return "Nieznany";
        }
    };

    return (
        <TableRow key={uuid()}>
            <TableCell className="text-center">{title}</TableCell>
            <TableCell>{handleTranslate(status)}</TableCell>
            <TableCell>{formatPrice(investedAmount)} PLN</TableCell>
            <TableCell>{totalYield} %</TableCell>
            <TableCell>{formatPrice(foundsToBeWithdrawn)} zł</TableCell>
            <TableCell>{formatPrice(foundsWithdrawn)} zł</TableCell>
            <TableCell>{day}.{month}.{year}</TableCell>
            <TableCell style={{ position: "relative" }}>
                <Box sx={{ position: 'relative' }}>
                    <Tooltip title="">
                        <IconButton
                            onClick={handleClose}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <GoGear />
                        </IconButton>
                    </Tooltip>
                    <Box
                        id="account-menu"
                        onClick={handleClose}
                        sx={{ position: 'absolute', left: '-260px', backgroundColor: 'white', border: '1px solid #e0e0e0', borderRadius: '10px', zIndex: 1000, display: open ? 'block' : 'none' }}
                    >
                        <MenuItem onClick={() => { navigate(`/investment/${id}`) }}>
                            <ListItemIcon>
                                <GoEye />
                            </ListItemIcon>
                            Szczegóły
                        </MenuItem>
                        <MenuItem onClick={() => { navigate(`/investment/${id}/progress`) }}>
                            <ListItemIcon>
                                <TfiMedall />
                            </ListItemIcon>
                            Przebieg realizacji
                        </MenuItem>
                        <MenuItem onClick={() => handleOpenModal(id)}>
                            <ListItemIcon>
                                <TfiMinus />
                            </ListItemIcon>
                            Prośba o zwrot
                        </MenuItem>
                        <MenuItem onClick={() => { window.open('https://signius.pl', '_blank') }}>
                            <ListItemIcon>
                                <TfiFile />
                            </ListItemIcon>
                            Przejdź do Signius
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <TfiFiles />
                            </ListItemIcon>
                            Pobierz podpisane dokumenty
                        </MenuItem>
                    </Box>
                </Box>
            </TableCell>
        </TableRow >
    );
};

export default TableRows;
