import { Box } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const Progress = () => {

    const params = useParams()

    const getInfo = () => {
        axios.get(`/projects/${params.id}/progress`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            console.log("data progress: ", res.data)

        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getInfo()
    }, [])

    return (
        <Box sx={{ marginTop: "150px", marginX: 'auto' }}>
            brak progresu
        </Box>
    )
}

export default Progress
