import { TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';

const TableRowsTransacts = ({ title, status, investedAmount, statusTransact }) => {
    const [statusTranslate, setStatusTranslate] = useState('')
    const [statusTransactTranslate, setStatusTransactTranslate] = useState('')

    useEffect(() => {
        if (status === 'failure') {
            setStatusTranslate('niepowodzenie')
        }
        if (status === 'success') {
            setStatusTranslate('sukces')
        }
        if (status === 'closed') {
            setStatusTranslate('zamknięte')
        }
        if (statusTransact === 'paid') {
            setStatusTransactTranslate('opłacone')
        }
        if (statusTransact === 'refund') {
            setStatusTransactTranslate('Pożyczka spłacona')
        }
    }, [])

    return (
        <TableRow key={uuid()}>
            <TableCell>{title}</TableCell>
            <TableCell>{statusTranslate}</TableCell>
            <TableCell>{statusTransactTranslate}</TableCell>
            <TableCell>{investedAmount} PLN</TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

export default TableRowsTransacts