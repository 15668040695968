import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Paper, Typography, Select, MenuItem, Pagination, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import uuid from 'react-uuid';

const CustomTab = styled(Tab)(({ theme, selected }) => ({
    backgroundColor: selected ? '#fff' : 'inherit',
}));

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CrowderTabPanelMyInvestments = ({
    TabsEl,
    handleChange,
    tabValue,
    handleFilterRows,
    rowsPerPage,
    isRowsPerPage,
    handlePage,
    offsetPage
}) => {

    return (
        <Box sx={{ marginTop: "100px", width: 'calc(100% - 60px)', padding: "20px 30px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="fullWidth"
                    value={tabValue}
                    onChange={handleChange}
                >
                    {
                        TabsEl.map((tabel, i) => {
                            return (
                                <CustomTab key={uuid()} label={tabel.pl} {...a11yProps(i)} />
                            )
                        })
                    }
                </Tabs>
            </Box>
            {TabsEl.map((tabel, i) => {
                return (
                    <CustomTabPanel key={uuid()} value={tabValue} index={tabel.id}>
                        <Box sx={{ display: "flex", alignItems: 'flex-start', flexDirection: { xs: 'column', md: "row" } }} marginTop={2}>
                            <Paper sx={{ padding: "20px", marginBottom: { xs: "16px", md: "0" }, marginRight: { xs: "0", md: "16px" }, width: { xs: "100%", md: "initial" }, }} >
                                <Typography fontSize={24} fontWeight={700} marginBottom={3}>Zainwestowane środki</Typography>
                                <Box display={"flex"} justifyContent={"center"} >
                                    {tabel.investedAmountChart}
                                </Box>
                            </Paper>
                            <Paper sx={{ padding: "20px", overflow: "hidden", maxWidth: "100%" }} marginLeft={2}>
                                <Box marginBottom={2}>
                                    <Select
                                        value={rowsPerPage}
                                        onChange={(event) => handleFilterRows(event.target.value)}
                                        size='small'
                                    >
                                        <MenuItem value={10}>10 </MenuItem>
                                        <MenuItem value={20}>20 </MenuItem>
                                        <MenuItem value={50}>50 </MenuItem>
                                    </Select>
                                </Box>
                                <Box sx={{ backgroundColor: "#fff", padding: "16px", maxWidth: "100%", boxShadow: "0px 2px 17px rgba(0, 0, 0, 0.1) ", overflow: "auto", }} marginBottom={2}>
                                    <Box sx={{ minWidth: "800px", }} >
                                        <div style={{ position: "sticky", top: "-20px", }}>
                                            <Table style={{ tableLayout: "fixed", }}  >
                                                <TableHead>
                                                    <TableRow
                                                        style={{
                                                            backgroundColor: "#ffffff",
                                                            height: "35px",
                                                            borderBottom: "1px solid #f5f5f5"
                                                        }}
                                                    >
                                                        <TableCell >Nazwa Projektu</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Zainwestowana kwota</TableCell>
                                                        <TableCell >Prognozowany Zysk</TableCell>
                                                        <TableCell>Środki do wypłaty</TableCell>
                                                        <TableCell>Środki wypłacone</TableCell>
                                                        <TableCell>Data wykonania</TableCell>
                                                        <TableCell>Akcje</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </div>
                                        <div style={{ height: "300px", backgroundColor: "#ffffff", }}>
                                            <Table style={{ tableLayout: "fixed", }}>
                                                <TableBody>
                                                    {tabel.Content}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Box>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography>{tabel.Content.length - (tabel.Content.length - 1) + offsetPage} - {tabel.Content.length > 0 ? offsetPage + tabel.Content.length : <CircularProgress size={15} sx={{ marginBottom: "-2px" }} />}</Typography>
                                    <Pagination onChange={handlePage} sx={{ textAlign: "center" }} count={isRowsPerPage ? tabel.projectCounts / rowsPerPage + 1 : 1} defaultPage={offsetPage === 0 ? 1 : offsetPage / rowsPerPage + 1} siblingCount={0} boundaryCount={2} />
                                </Box>
                            </Paper>
                        </Box>
                    </CustomTabPanel>
                )
            })}
        </Box>
    );
}

export default CrowderTabPanelMyInvestments;