import { Box, LinearProgress } from '@mui/material'
import { Line } from 'react-chartjs-2'

const LineWithdrawnChart = ({ statsLoader, labelsWithdrawn, withdrawnMonthly }) => {

    const withdrawnSorted = withdrawnMonthly.sort((a, b) => {
        if (a.year === b.year) {
            return a.month - b.month;
        }
        return a.year - b.year
    });

    const dataMonthly = {
        labels: labelsWithdrawn !== undefined && labelsWithdrawn.map((month, i) => { return `${month} ${withdrawnSorted[i].year}` }),
        datasets: [
            {
                data: withdrawnSorted !== undefined ? withdrawnSorted.map(withdrawn => { return withdrawn.amount / 100 }) : [0],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        xAxes: [{
            ticks: {
                callback: (v, i) => i + 1 <= labelsWithdrawn.length ? v : null
            }
        }]
        ,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };

    return (
        <Box position={'relative'} width={"100%"} height={"300px"}>
            {
                statsLoader
                    ?
                    <LinearProgress />
                    :
                    labelsWithdrawn.length > 0
                    &&
                    <Line data={dataMonthly} options={options} />
            }
        </Box>
    )
}

export default LineWithdrawnChart